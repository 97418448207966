<script>
import { ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import Button from '../../../components/common/Button.vue'
import 'vue-json-viewer/style.css'

export default {
  components: { Button },
    props: {
        quest: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: false,
            default: "Missing Description"
        },
        index: {
            type: Number,
            required: true
        },
        onCompleted: {
            type: Function,
            required: false
        },
        onDeleted: {
            type: Function,
            required: true
        },
        category: {
            type: String,
            required: false
        }
    },
    setup(props) {
        const isExtended = ref(false)
        const questObject = ref({})

        onMounted(() => {
            questObject.value = JSON.parse(JSON.stringify(props.quest))
        })

        const swithExtended = () => {
            isExtended.value = true;
        }

        const onDeleteClicked = (event) => {
            props.onDeleted();
            event.stopPropagation()
        }

        const onApplyClicked = () => {
            isExtended.value = false
            props.onCompleted(questObject.value)
        }

        return {
            isExtended,
            swithExtended,
            questObject,
            onApplyClicked,
            onDeleteClicked
        }
    }
}
</script>

<template>
    <div :class="`quest-container ${isExtended ? '' : 'quest-container-not-extended'}`">
        <div class="container-header" @click="swithExtended()">
            <p>{{ description }}</p>
            <div class="flex">
                <Button type="error" :onClick="(event) => onDeleteClicked(event)" :loading="false" text="Remove" size="sm" />
                <i :class="`fa fa-caret-${isExtended ? 'up' : 'down'}`"></i>
            </div>
        </div>
        <div v-if="isExtended" class="container-body">
            <div class="quest-form-container">
                <p>Category</p>
                <input v-model="questObject.category" />
            </div>
            <div class="quest-form-container">
                <p>Min Trophy</p>
                <input v-model.number="questObject.min_trophy" />
            </div>
            <div class="quest-form-container">
                <p>Max Trophy</p>
                <input v-model.number="questObject.max_trophy" />
            </div>
            <div class="quest-form-container">
                <p>Amount</p>
                <input v-if="['Daily', 'Beginner'].includes(type)" v-model.number="questObject.amount" />
            </div>
            <div v-if="type === 'Seasonal'" class="quest-form-container padding-form">
                <p>L</p>
                <input v-model.number="questObject.amount.L" />
            </div>
            <div v-if="type === 'Seasonal'" class="quest-form-container padding-form">
                <p>M</p>
                <input v-model.number="questObject.amount.M" />
            </div>
            <div v-if="type === 'Seasonal'" class="quest-form-container padding-form">
                <p>S</p>
                <input v-model.number="questObject.amount.S" />
            </div>
            <div class="quest-form-container">
                <p></p>
                <Button type="info" :onClick="() => onApplyClicked()" :loading="false" text="Apply" />
            </div>
            <div class="quest-form-container jsondata">
                <json-viewer sort boxed copyable :value="questObject" />
            </div>
        </div>
    </div>
</template>

<style scoped>

.quest-container {
    border: 1px solid gray;
    border-radius: 5px;
    width: 100%;
    padding: 0px 10px;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.quest-container-not-extended :hover {
    cursor: pointer;
}

.container-header {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.container-body {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 10px;
}

.quest-form-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.flex {
    display: flex;
    flex-direction: row;
}

input {
    height: 25px;
}

.jsondata{
    text-align: left;
}

</style>